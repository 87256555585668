<template>
    <v-dialog v-model="dialog" width="800">
        <template v-slot:activator="{ on, attrs }">
            <v-btn v-if="isNew" @click="clearData" v-on="on" v-bind="attrs" color="green" depressed class="white--text">
                {{ $t("Create Category") }}
            </v-btn>
            <v-icon v-if="!isNew" @click="fetchdata" v-on="on" v-bind="attrs" color="blue">mdi-pen</v-icon>
        </template>
        <v-card>
            <v-card-title class="justify-center">
                <h1>{{ isNew ? $t("Create Category") : $t('edit') }} {{ item.name }}</h1>
            </v-card-title>
            <v-card-text>
                <v-container fluid>
                    <v-row justify="center">
                        <app-image-input-file :srcImage="structure.image ? getImgUrl(structure.image) : ''"  :resetImage="resetImageInput" :createFeature="true"
                             :imgHeight="140" :imgWidth="125" :placeHolder="$t('image')" @fileValue="setMealPhoto($event)"
                                @fileRemoved="deleteMealPhoto"></app-image-input-file>
                                <v-col md="2"></v-col>
                        <v-col md="4" class="py-10">
                            <v-text-field dense :dark="$store.state.isDarkMode" :label="$t('Category Name')" outlined 
                                    v-model="structure.name">
                            </v-text-field>
                        </v-col>                      
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-btn color="green" depressed class="white--text" @click="submit" :loading="btnLoading">{{ isNew ? $t("Create Category") : $t('edit')
                }}</v-btn>
                <v-btn color="blue darken-1" text @click="clearData">
                    {{ $t('cancel') }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import axios from "axios";
import AutoCompleteField from "../components/core/AutoCompleteField.vue";
import imageInputFile from "../../../components/base/imageInputFile.vue";
export default {
    components: {
        AutoCompleteField,
        appImageInputFile: imageInputFile,
    },
    data() {
        return {
            btnLoading: false,
            dialog: false,
            structure: {
                name: null,
                image: null,
            },
            lastimg: null,
        };
    },
    props: {
        isNew: {
            type: Boolean,
            default: true,
        },
        item: {
            type: Object,
            default: () => {
                return {};
            },
        }
    },
    methods: {
        getImgUrl(item) {
            let domain = "https://bahlol-back.incentive-dev.com/"
            let item2 = item.replace("public", "storage")
            return domain + item2
        },
        deleteMealPhoto() {
            this.structure.image = null;
        },
        setMealPhoto(file) {
            this.structure.image = file;
        },
        async fetchdata() {
            this.structure = this.item;
            if(this.structure.image){
                this.lastimg = this.structure.image;
            }
        },
        async submit() {
            try {
                this.btnLoading = true;
                let form = new FormData();
                if((this.lastimg && this.structure.image != this.lastimg) || (!this.lastimg && this.structure.image)) form.append('image', this.structure.image); 
                form.append('name', this.structure.name);
                if(this.isNew){
                    await axios.post("/meal-group", form);
                    this.$Notifications(
                        this.$t('add success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                } else {
                    await axios.post(`/meal-group/${this.item.id}`, form);
                    this.$Notifications(
                        this.$t('edit success'),
                        { rtl: true, timeout: 2000 },
                        "success"
                    );
                }
                this.$emit("Submitted");
                this.clearData();
            } catch (err) {
            } finally {
                this.btnLoading = false;
            }
        },
        clearData() {
            this.structure.name = null;
            this.structure.image = null;
            this.dialog = false;
        },
    },
};
</script>
  
<style>


.btn {
  text-decoration: none;
  height: 41px;
  color: white;
  min-width: 73px;
  padding: 0 18.2222222222px;
  font-size: 1rem;
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  font-weight: 400;
  letter-spacing: 0;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  outline: 0;
  position: relative;
  text-decoration: none;
  text-indent: 0;
  text-transform: uppercase;
  -webkit-transition-duration: 0.28s;
  transition-duration: 0.28s;
  -webkit-transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: opacity, -webkit-box-shadow, -webkit-transform;
  transition-property: box-shadow, transform, opacity;
  transition-property: box-shadow, transform, opacity, -webkit-box-shadow,
    -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}

</style>